@font-face {
    font-family: 'SProText-Medium';
    src: local('SProText-Medium'), url(./assets/fonts/SFProText-Medium.ttf) format('truetype');
}
@font-face {
    font-family: 'SProText-Regular';
    src: local('SProText-Regular'), url(./assets/fonts/SFProText-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'Montserrat-Bold';
    src: local('Montserrat-Bold'), url(./assets/fonts/Montserrat-Bold.ttf) format('truetype');
}
